import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

@Component({
  selector: 'app-gestao-acesso-expansion',
  templateUrl: './gestao-acesso-expansion.component.html',
  styleUrl: './gestao-acesso-expansion.component.scss'
})
export class GestaoAcessoExpansionComponent implements AfterViewInit {
  @ViewChild('gestaoAcessoExpansionPanel') expansionPanel?: MatExpansionPanel;
  private gestaoAcessoService = inject(GestaoAcessoService);
  constructor(private router: Router) {}
  validaAcessoCadastrarPerfil$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'cadastrarPerfil'
  });
  validaAcessoPesquisaUsuario$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'pesquisaUsuario'
  });
  validaAcessoPesquisaPerfil$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'pesquisaPerfil'
  });

  validaAcessoCadastroUsuario$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'cadastrarUsuario'
  });

  validaAcessoGestaoCliente$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'gestaoCliente'
  });

  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  abrir() {
    const ativo = this.router.isActive('/usuario', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }

  ngAfterViewInit(): void {
    this.abrir();
  }
}
