<mat-expansion-panel #gestaoAcessoExpansionPanel class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header matRipple class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo">
      Gestão de acesso
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (validaAcessoPesquisaUsuario$ | async) {
    <a matRipple class="c-collapse-menu-lateral_link" [routerLink]="['/gestaoAcesso/obterUsuario']"
      routerLinkActive="is-ativo" (isActiveChange)="rolarParaInicio()">
      Pesquisa usuários
    </a>
    }
    @if (validaAcessoPesquisaPerfil$ | async) {
    <a matRipple class="c-collapse-menu-lateral_link" [routerLink]="['/gestaoAcesso/obterPerfil']"
      routerLinkActive="is-ativo" (isActiveChange)="rolarParaInicio()">
      Pesquisa perfis
    </a>
    }
    @if (validaAcessoCadastrarPerfil$ | async) {
    <a matRipple class="c-collapse-menu-lateral_link" [routerLink]="['/gestaoAcesso/cadastrarPerfil']"
      routerLinkActive="is-ativo" (isActiveChange)="rolarParaInicio()">
      Cadastrar perfil
    </a>
    }
    @if (validaAcessoCadastrarPerfil$ | async) {
    <a matRipple class="c-collapse-menu-lateral_link" [routerLink]="['/gestaoAcesso/cadastrarUsuario']"
      routerLinkActive="is-ativo" (isActiveChange)="rolarParaInicio()">
      Cadastrar Usuários
    </a>
    }

    @if (validaAcessoGestaoCliente$ | async) {
    <a matRipple class="c-collapse-menu-lateral_link" [routerLink]="['/gestaoAcesso/gerenciarClientes']"
      routerLinkActive="is-ativo" (isActiveChange)="rolarParaInicio()">
      Gerenciar Clientes
    </a>
    }
  </div>
</mat-expansion-panel>